<template>
  <div>
    <app-bar-login></app-bar-login>

    <div class="container">
      <v-form v-model="isFormValid">
        <v-row>
          <v-spacer></v-spacer>

          <v-col cols="12" lg="5">
            <v-col cols="12">
              <v-text-field
                label="Username"
                v-model="username"
                :rules="usernameRules"
                type="text"
                autofocus
                prepend-icon="mdi-account"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="E-mail"
                v-model="email"
                :rules="emailRules"
                type="text"
                autofocus
                prepend-icon="mdi-email"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <!-- password -->
              <v-text-field
                v-model="password"
                label="Password"
                prepend-icon="mdi-lock-outline"
                type="password"
                :rules="passwordRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- password1 -->
              <v-text-field
                v-model="passwordConfirm"
                :label="$t('signupPage.verify-password')"
                prepend-icon="mdi-lock-outline"
                type="password"
                :rules="confirmPasswordRules"
              >
              </v-text-field>
            </v-col>
            <v-row v-if="errorSignup">
              <v-col cols="12">
                <v-alert v-if="errorSignup" dense outlined type="error">
                  {{ errorSignupMessage }}
                </v-alert>
              </v-col></v-row
            >

            <v-row v-if="signupCompleted">
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-alert
                  v-if="signupCompleted"
                  dense
                  outlined
                  type="success"
                  v-t="'signupPage[\'account-created\']'"
                >
                </v-alert></v-col
              ><v-spacer></v-spacer
            ></v-row>
            <v-row v-if="errorVerificationCode">
              <v-col cols="12">
                <v-alert
                  v-if="errorVerificationCode"
                  dense
                  outlined
                  type="error"
                >
                  {{ errorSignupMessage }}
                </v-alert>
              </v-col></v-row
            >
            <v-form v-model="isFormValid" v-if="signupCompleted">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('signupPage.verification-code')"
                    v-model="verificationCode"
                    :rules="verificationCodeRules"
                    type="number"
                    autofocus
                    prepend-icon="mdi-account"
                  >
                  </v-text-field>
                </v-col>
                <v-spacer></v-spacer
                ><v-col cols="6"
                  ><v-btn
                    class="nandocolor mt-2"
                    @click="signUpVerifyCode"
                    v-t="'signupPage[\'check-code\']'"
                  ></v-btn
                ></v-col>
              </v-row>
              <v-row
                ><v-spacer></v-spacer
                ><v-col lg="6" cols="12"
                  ><v-btn
                    class="nandocolor mt-2"
                    @click="resendConfirmationCode"
                    v-t="'signupPage[\'verification-code-resend\']'"
                  ></v-btn></v-col
                ><v-spacer></v-spacer
              ></v-row>
            </v-form>
            <v-container class="">
              <v-row>
                <v-btn
                  v-if="!signupCompleted"
                  class="nandocolor mx-auto"
                  @click="signUp"
                  :disabled="!isFormValid"
                  v-t="'signupPage[\'register\']'"
                ></v-btn>
              </v-row>
              <v-row><v-col></v-col></v-row>

              <v-row
                ><span
                  class="mx-auto"
                  v-t="'signupPage[\'already-have-account\']'"
                ></span
              ></v-row>
              <v-row><v-col></v-col></v-row>

              <v-row>
                <v-btn class="nandocolor mx-auto" @click="login">Login</v-btn>
              </v-row></v-container
            > </v-col
          ><v-spacer></v-spacer
        ></v-row>
      </v-form>

      <v-dialog
        v-model="signupCompletedCodeVerified"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5" v-t="'signupPage[\'success\']'">
          </v-card-title>

          <v-card-text v-t="'signupPage[\'success-2\']'"> </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="
                dialog = false;
                login();
              "
              v-t="'signupPage[\'go-to-login\']'"
            >
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import AppBarLogin from "../components/AppBarLogin.vue";

export default {
  name: "Login",
  components: { AppBarLogin },
  data() {
    return {
      isFormValid: false,
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      errorSignup: false,
      errorVerificationCode: false,
      errorSignupMessage: "",
      passwordRules: [
        (v) => !!v || this.$t("required-field"),

        (v) => (v && v.length >= 8) || this.$t("password-rules"),
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t("required-field"),
        (v) => (v && v == this.password) || this.$t("password-must-match"),
      ],
      emailRules: [(v) => !!v || this.$t("required-field")],
      usernameRules: [(v) => !!v || this.$t("required-field")],
      verificationCodeRules: [(v) => !!v || this.$t("required-field")],
      verificationCode: "",
      signupCompleted: false,
      signupCompletedCodeVerified: false,
      stepVerificationCode: false,
    };
  },
  methods: {
    async signUp() {
      try {
        this.errorSignup = false;
        await Auth.signUp(
          {
            username: this.email,
            password: this.password,
            attributes: { nickname: this.username },
          },
          []
        ).then(() => {
          this.signupCompleted = true;
        });
      } catch (error) {
        this.errorSignup = true;
        this.errorSignupMessage = error.message;
        console.log(error.message, error);
      }
    },

    async resendConfirmationCode() {
      try {
        await Auth.resendSignUp(this.email);
      } catch (err) {
        this.errorVerificationCode = true;
        this.errorSignupMessage = err.message;
        console.log(err.message);      }
    },

    async signUpVerifyCode() {
      this.signupCompletedCodeVerified = false;

      try {
        this.errorVerificationCode = false;
        await Auth.confirmSignUp(this.email, this.verificationCode).then(() => {
          this.signupCompletedCodeVerified = true;
        });
      } catch (error) {
        this.errorVerificationCode = true;
        this.errorSignupMessage = error.message;
        console.log(error.message);
      }
    },

    login() {
      this.$router.push("/login");
    },
  },
};
</script>
